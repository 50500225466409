<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
// import { clueOption } from './../config'

export default {
  components:{ shortConsumerInfo },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
  },
  computed:{
    getTypeName() {
      const { type } = this.dataSource?.reviewVo || {}
      const obj={
        1:this.$t('定金协议'),
        2:this.$t('购车合同')
      }
      return type && obj[type] || ''
    },
    getStatusName() {
      const { status } = this.dataSource?.reviewVo || {}
      const obj={
        1:this.$t('待审核'),
        5:this.$t('已审核'),
        10:this.$t('已驳回')
      }
      return status && obj[status] || ''
    },
    // 能否领取订单--已下定2000050/已锁单2000055/已签合同2000060不可领取
    // 能否领取订单--定金待支付2000050 定金已支付2000052 已锁单2000055 已推合同2000057 已签合同2000060
    showReceive() {
      // return this.dataSource.state !== '2000050' && this.dataSource.state !== '2000055' && this.dataSource.state !== '2000060'
      return !['2000050', '2000052', '2000055', '2000057', '2000060'].includes(this.dataSource.state)
    }
  },
  methods:{
    goDetail(item) {
      const { isRecycle, reviewVo } = item
      if (isRecycle === 0 && reviewVo){
        this.$emit('goDetail',item)
      }
    },    
  }
}
</script>
<template>
  <div class="item" @click.stop="goDetail(dataSource)">
    <div class="user-status">
      <shortConsumerInfo :data="dataSource" :showLevel="false" :showIntention="false" :showSex="false"></shortConsumerInfo>
      <div :class="['status']">{{ dataSource.state | codeFormat }} </div>
    </div>
    <template v-if="dataSource.reviewVo">
      <div class="van-ellipsis info-item">
        <span class="label">{{$t('申请状态')}}：</span>
        <span>{{getTypeName}}{{ getStatusName }}</span>
      </div>
      <div class="van-ellipsis info-item">
        <span class="label">{{$t('销售顾问')}}：</span>
        <span>{{dataSource.followUserName}}</span>
      </div>
    </template>
    <template v-if="!dataSource.followUserId || dataSource.isRecycle === 1">
      <div>{{$t('该客户还未分配销售顾问')}}</div>
      <div class="btns">
        <div v-if="showReceive" class="btn" @click.stop="$emit('onReceive',dataSource)">{{$t('立即领取')}}</div>
      </div>
    </template>
   <!-- !reviewVo isRecycle === 0未回收  -->
    <template v-else-if="!dataSource.reviewVo && dataSource.isRecycle === 0">
      <div class="btns">
        <div v-if="showReceive" class="btn" @click.stop="$emit('creat',dataSource)">{{$t('发起线下下单申请')}}</div>
      </div>
    </template>
  </div>
</template>
<style lang="less" scoped>
.item{
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 12px 12px;
  font-size: 13px;
  .user-status{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    padding-bottom: 8px;
    margin-bottom: 12px;
    .status{
      width: 70px;
      text-align: right;
    }
  }
  .info-item{
    margin-bottom:8px;
    .label{
      color: #929798;
    }
  }
  .btns{
    display: flex;
    flex-direction: row-reverse;
    color: #B9921A;
    font-size: 14px;
    .btn{
      cursor: pointer;
    }
  }
}
</style>
